<template>
  <div>
    <section class="request-details section">
      <div class="wrapper">
        <div class="mt-20 details-table">
          <div class="row">
            <h5 class="row-title">
              {{ $t("request.details.destination") }}
            </h5>
            <p class="font-sans text-black-base">
              {{ destination }}
            </p>
          </div>
          <div class="row">
            <h5 class="row-title">
              {{ $t("request.details.group") }}
            </h5>
            <p class="font-sans text-black-base">
              {{ group_type }}
            </p>
          </div>

          <div class="row">
            <h5 class="row-title">
              {{ $t("request.details.date") }}
            </h5>
            <p class="font-sans text-black-base">
              {{ date }}
            </p>
          </div>

          <div class="row">
            <h5 class="row-title">
              {{ $t("request.details.budget") }}
            </h5>
            <div class="font-sans">
              <div v-if="isBaseBudget" class="text-black-base text-right">{{ baseBugdet | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }}</div>
              <div v-else class="text-black-base text-right">{{ budget | currency(currency) }}</div>
              <div v-if="isBaseBudget" class="text-black-lighter text-sm text-right">{{ baseBugdet | currency(baseCurrency) }}</div>
            </div>
          </div>
          <div class="row">
            <h5 class="row-title">
              {{ $t("request.details.type") }}
            </h5>
            <p class="font-sans text-black-base">
              {{ trip_reason }}
            </p>
          </div>
          <div class="row">
            <h5 class="row-title">
              {{ $t("request.details.number") }}
            </h5>
            <div class="values xs:text-right">
              <p class="font-sans text-black-base">
                {{
                  $tc("request.details.travelers", travelers, {
                    count: travelers,
                  })
                }}
              </p>
              <span class="font-sans text-sm text-black-lighter">
                {{ $tc("request.details.adults", adults, { count: adults })
                }}{{
                  $tc("request.details.children", children, { count: children })
                }}
              </span>
            </div>
          </div>

          <div class="row">
            <h5 class="row-title">
              {{ $t("request.details.meal") }}
            </h5>
            <p class="font-sans text-black-base">
              {{ meal_plan }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { humanReadableDateRange } from "../../../shared/dateHandler.js";
export default {
  components: {},
  data() {
    return {
      lang: localStorage.getItem("lang") || "en",
      groupType: {
        Couple: "Couple",
        Family: "Famille",
        Friends: "Amis",
        Organisation: "Organisation (Entreprise, église, école, ONG, etc.)",
        Solo: "Solo",
        Other: "Autre"
      },
      tripReason: {
        "Bachelor party": "Enterrement de vie de garçon / de jeune fille",
        Birthday: "Anniversaire",
        Honeymoon: "Lune de miel",
        Retreat: "Retraite",
        "Study or school trip": "Voyage d'étude ou scolaire",
        "Vacation and recreation": "Vacances et loisirs",
        Other: "Autre motif"
      },
      mealPlan: {
        Breakfast: "Petit-déjeuner",
        "Half board": "Demi-pension",
        "Full board": "Pension complète"
      },
      destinations: {
        Benin: "Bénin",
        "Cape Verde": "Cap-Vert",
        "Côte d'Ivoire": "Côte d'Ivoire",
        "Democratic Republic of the Congo": "République démocratique du Congo",
        Ghana: "Ghana",
        Kenya: "Kenya",
        Morocco: "Maroc",
        Namibia: "Namibie",
        Rwanda: "Rwanda",
        Senegal: "Sénégal",
        "South Africa": "Afrique du Sud",
        Tanzania: "Tanzanie"
      },
    };
  },
  computed: {
    ...mapGetters("user", ["tripDetails"]),
    ...mapGetters({
      rates: "exchange/rates",
      getCurrency: "global/currency",
    }),
    destination() {
      if (this.lang === "en")
        return this.tripDetails.trip_destination;

      return this.destinations[this.tripDetails.trip_destination];
    },
    group_type() {
      if (this.lang === "en")
        return this.tripDetails.trip_group_type

      return this.groupType[this.tripDetails.trip_group_type];
    },
    trip_reason() {
      if (this.lang === "en")
        return this.tripDetails.trip_reason;

      return this.tripReason[this.tripDetails.trip_reason];
    },
    meal_plan() {
      if (this.lang === "en")
        return this.tripDetails.trip_meal_plan;

      return this.mealPlan[this.tripDetails.trip_meal_plan];
    },
    date() {
      let dates = this.tripDetails.trip_dates;
      if (dates.includes(",")) {
        return dates;
      }


      return humanReadableDateRange(dates);
    },
    travelers() {
      let count = this.tripDetails.trip_adults + this.tripDetails.trip_children;
      return count;
    },
    adults() {
      return this.tripDetails.trip_adults;
    },
    children() {
      return this.tripDetails.trip_children;
    },
    dateRegion() {
      if (this.lang == "en") return "en-US";
      return "fr-FR";
    },
    currencyRegion() {
      let currency = this.tripDetails.trip_currency;
      if (currency == "USD") return "en-US";
      if (currency == "CHF") return "ch-DE";
      if (currency == "GBP") return "en-EN";
      return "fr-FR";
    },
    isBaseBudget() {
      return this.baseBugdet > 0 ? true : false;
    },
    budget() {
      return this.tripDetails.trip_budget;
    },
    baseBugdet() {
      return this.tripDetails.base_trip_budget
    },
    currency() {
      return this.tripDetails.trip_currency;
    },
    baseCurrency() {
      return this.tripDetails.base_trip_currency;
    }
  },
}
</script>
<style scoped>
.section {
  @apply px-4;
}
.wrapper {
  @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}
.row {
  @apply flex flex-wrap py-3 border-b xs:justify-between border-grey-base;
}
.row-title {
  @apply w-full mb-1 font-sans font-semibold text-black-base xs:w-auto;
}
</style>
