<template>
  <section class="section" id="pricing">
    <div class="wrapper">
    <h2 class="section-title">{{ $t("offer.pricing.title") }}</h2>
    <div class="mt-2 pricing">
      <PricingBlocks 
        :adult-price="pricePerAdult"
        :child-price="pricePerChild" 
        :children="offer.trip_children" 
        :single-room-price="offer.trip_single_room_price" 
        :single-rooms="offer.trip_single_rooms"
        :currency="offer.trip_currency"
        :base-currency="baseCurrency"
        :base-single-room-price="baseSingleRoomPrice"
        :base-child-price="baseChildPrice"
        :base-adult-price="baseAdultPrice"
        :is-base="isBase"
      />
      <h3 class="section-sub-title">{{ $t("offer.pricing.title") }}</h3>
      <div class="mt-4">
        <div v-if="isBase" class="flex justify-between items-center text-black-base">
          <div class="calculation">{{ baseAdultPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }} × {{ adults }}</div>
          <div class="result">
            {{ totalBaseAdultsPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }}
          </div>
        </div>
        <div v-else class="flex justify-between items-center text-black-base">
          <div class="calculation">{{ pricePerAdult | currency(currency) }} × {{ adults }}</div>
          <div class="result">
            {{ totalAdultsPrice | currency(currency) }}
          </div>
        </div>
        <div v-if="isBase" class="flex justify-between items-center text-sm text-black-lighter">
          <div class="calculation">{{ baseAdultPrice | currency(baseCurrency) }} × {{ adults }}</div>
          <div class="result">
            {{ totalBaseAdultsPrice | currency(baseCurrency) }}
          </div>
        </div>
      </div>

      <Divider class="w-full my-4"/>

      <div v-if="offer.trip_children > 0">
        <div v-if="isBase" class="flex justify-between items-center text-black-base">
        <div class="calculation">{{ baseChildPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }} × {{ children }}</div>
        <div class="result">{{ totalBaseChildrenPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }}</div>        
       </div>
       <div v-else class="flex justify-between items-center text-black-base">
        <div class="calculation">{{ pricePerChild | currency(currency) }} × {{ children }}</div>
        <div class="result">{{ totalChildrenPrice | currency(currency) }}</div>        
       </div> 
       <div v-if="isBase" class="flex justify-between items-center text-sm text-black-lighter">
          <div class="calculation">{{ baseChildPrice | currency(baseCurrency) }} × {{ children }}</div>
          <div class="result">{{ totalBaseChildrenPrice | currency(baseCurrency) }}</div>
        </div>
      </div>

      <Divider v-if="offer.trip_children > 0" class="w-full my-4"/>

      <div v-if="totalSingleRoomPrice > 0">
        <div class="mt-4">
          <div v-if="isBase" class="flex justify-between items-center text-black-base">
            <div class="fee">{{ baseSingleRoomPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }} × {{ singleRooms }}</div>
            <div class="result">
              {{ baseSingleRoomPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }}
            </div>
          </div>
          <div v-else class="flex justify-between items-center text-black-base">
            <div class="fee">{{ pricePerSingleRoom | currency(currency) }} × {{ singleRooms }}</div>
            <div class="result">
              {{ totalSingleRoomPrice | currency(currency) }}
            </div>
          </div>
          <div v-if="isBase" class="flex justify-between items-center text-sm text-black-lighter">
            <div class="fee">{{ baseSingleRoomPrice | currency(baseCurrency) }} × {{ singleRooms }}</div>
            <div class="result">
              {{ totalBaseSingleRoomPrice | currency(baseCurrency) }}
            </div>
          </div>
        </div>
      </div>

      <Divider v-if="totalSingleRoomPrice > 0" class="w-full mt-4"/>

      <div class="py-3">
        <div v-if="isBase" class="font-bold text-black-base flex justify-between items-center">
          <div class="total">{{ $t("offer.pricing.total") }}</div>
          <div class="result">{{ baseTotalPrice | currency(baseCurrency, currency, rates[`${baseCurrency}_${currency}`]) }}</div>
        </div>
        <div v-else class="font-bold text-black-base flex justify-between items-center">
          <div class="total">{{ $t("offer.pricing.total") }}</div>
          <div class="result">{{ totalPrice | currency(currency) }}</div>
        </div>
         <div v-if="isBase" class="font-semibold text-black-lighter text-sm flex justify-between items-center">
          <div class="total">{{ $t("common.totalIn") }} {{ baseCurrency }}</div>
          <div class="result">{{ baseTotalPrice | currency(baseCurrency) }}</div>
        </div>
      </div>

      <div class="items-section-container">
        <div class="inclusions items-section">
          <h3 class="font-sans text-lg font-semibold text-black-base">
            {{ $t("offer.scope.inclusions") }}
          </h3>
          <div class="mt-2 list">
            <div
                v-for="(inclusion, index) in offer.trip_inclusions.split(',')"
                :key="index"
                class="flex items-start mb-3"
            >
              <i class="text-lg bx bxs-check-circle inclusion-icon"></i>
              <div class="ml-2 font-sans text-black-base">
                {{ inclusion }}
              </div>
            </div>
          </div>
        </div>
        <div class="exclusions items-section">
          <h3 class="font-sans text-lg font-semibold text-black-base">
            {{ $t("offer.scope.exclusions") }}
          </h3>
          <div class="mt-2 list">
            <div
                v-for="(exclusion, index) in offer.trip_exclusions.split(',')"
                :key="index"
                class="flex items-start mb-3"
            >
              <i class="text-lg bx bxs-x-circle exclusion-icon"></i>
              <div class="ml-2 font-sans text-black-base">
                {{ exclusion }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>
<script>
import Divider from "@/components/dividers/Divider";
import PricingBlocks from "../../components/PricingBlocks.vue"
import { mapGetters } from "vuex";
export default {
  name: "PricingBlock",
  components: {
    Divider,
    PricingBlocks
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      region: "en-US",
      earningFees: 1 + this.offer.trip_earning_fees / 100,
    };
  },
  computed: {
    ...mapGetters({
      rates: "exchange/rates",
    }),
    adults() {
      let count = this.offer.trip_adults;
      return this.$tc("offer.pricing.adults", count, {
        count: count,
      });
    },
    children() {
      let count = this.offer.trip_children;
      return this.$tc("offer.pricing.children", count, {
        count: count,
      });
    },
    singleRooms() {
      let count = this.offer.trip_single_rooms;
      return this.$tc("offer.pricing.rooms", count, {count: count});
    },
    pricePerSingleRoom() {
      return this.offer.trip_single_room_price;
    },
    pricePerAdult() {
      return this.offer.trip_price_per_adult * this.earningFees;
    },
    pricePerChild() {
      return this.offer.trip_price_per_child * this.earningFees;
    },
    totalAdultsPrice() {
      return (
          this.offer.trip_price_per_adult *
          this.offer.trip_adults *
          this.earningFees
      );
    },
    totalChildrenPrice() {
      return (
          this.offer.trip_price_per_child *
          this.offer.trip_children *
          this.earningFees
      );
    },
    totalSingleRoomPrice() {
      return this.offer.trip_single_room_price * this.offer.trip_single_rooms;
    },
    totalPrice() {
      return  this.totalAdultsPrice + this.totalChildrenPrice + this.totalSingleRoomPrice;
    },
    isBase() {
      return parseInt(this.offer.base_trip_total_price) ? true : false;
    },
    currency() {
      return this.offer.trip_currency;
    },
    baseCurrency() {
      return this.offer.base_trip_currency;
    },
    baseTotalPrice() {
      return this.totalBaseAdultsPrice + this.totalBaseChildrenPrice + this.totalBaseSingleRoomPrice;
    },
    baseAdultPrice() {
      return this.offer.base_trip_price_per_adult * this.earningFees;
    },
    totalBaseAdultsPrice() {
      return (
          this.baseAdultPrice *
          this.offer.trip_adults 
      );
    },
    totalBaseChildrenPrice() {
      return (
          this.baseChildPrice *
          this.offer.trip_children 
      );
    },
    baseChildPrice() {
      return this.offer.base_trip_price_per_child * this.earningFees;
    },
    baseSingleRoomPrice() {
      return this.offer.base_trip_single_room_price;
    },
    totalBaseSingleRoomPrice() {
      return this.baseSingleRoomPrice * this.offer.trip_single_rooms;
    },
    adultPrice() {
      return this.offer.trip_price_per_adult * this.earningFees;
    },
    childPrice() {
      return this.offer.trip_price_per_child * this.earningFees;
    }
  },
};
</script>

<style scoped>
.inclusion-icon{
  @apply mt-1 text-green-base;
}

.exclusion-icon{
  @apply mt-1 text-red-base;
}
.items-section-container{
  @apply mt-12;
}

.exclusions{
  @apply mt-6;
}
.items-section{
  @apply w-full sm:w-calc-1/2;
}
.section-title {
  @apply mt-12 font-sans text-2xl font-semibold text-black-base;
}

.section-sub-title {
  @apply mt-6 font-sans text-xl font-semibold text-black-base;
}

.price-travelers {
  @apply inline-block p-4 mr-3 font-sans rounded-md bg-white shadow-sm border border-grey-base;
}

.price-per-traveler {
  @apply font-semibold text-black-base text-xl;
}

.price-label {
  @apply text-black-lightest text-base;
}

.pricing-table-row {
  @apply flex justify-between w-full text-black-base;
}

.section {
  @apply px-4 py-6;
}
.wrapper {
  @apply relative transform -translate-x-1/2 max-w-screen-sm left-1/2;
}
</style>
